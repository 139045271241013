import React, { useState } from "react";
import styles from "./index.module.css";
import CrossIcon from "../../../../../assets/images/CrossIcon.png";
import EyeOpen from "../../../../../assets/icons/EyeOpen.svg";
import EyeClosed from "../../../../../assets/icons/EyeClosed.svg";

const ResetPasswordModal = ({ isOpen, toggle, onReset }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validateNewPassword = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasSpecialChar) {
      return "Password must include at least one special character.";
    }
    if (!hasUppercase) {
      return "Password must include at least one uppercase letter.";
    }
    if (!hasLowercase) {
      return "Password must include at least one lowercase letter.";
    }
    if (!hasNumber) {
      return "Password must include at least one number.";
    }
    return "";
  };

  const handleResetPassword = () => {
    const newPasswordError = validateNewPassword(newPassword);
    const confirmPasswordError =
      newPassword !== confirmPassword ? "Passwords do not match." : "";

    setErrors({
      newPassword: newPasswordError,
      confirmPassword: confirmPasswordError,
    });

    // If there are no errors, proceed
    if (!newPasswordError && !confirmPasswordError) {
      onReset(newPassword);
      toggle();
    }
  };

  return (
    isOpen && (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2>Set Password</h2>
            <img
              src={CrossIcon}
              className={styles.closeButton}
              onClick={toggle}
              height={30}
              width={30}
              alt='cross'
            />
          </div>
          <div className={styles.modalBody}>
            <div className={styles.formGroup}>
              <label htmlFor='newPassword'>New Password</label>
              <div className={styles.passwordWrapper}>
                <input
                  type={showNewPassword ? "text" : "password"}
                  id='newPassword'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder='Enter new password'
                  className={errors.newPassword ? styles.inputError : ""}
                />
                <button
                  type='button'
                  className={styles.eyeButton}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <img src={EyeOpen} />
                  ) : (
                    <img src={EyeClosed} />
                  )}
                </button>
              </div>
              {errors.newPassword && (
                <p className={styles.errorText}>{errors.newPassword}</p>
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor='confirmPassword'>Confirm Password</label>
              <div className={styles.passwordWrapper}>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id='confirmPassword'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder='Confirm new password'
                  className={errors.confirmPassword ? styles.inputError : ""}
                />
                <button
                  type='button'
                  className={styles.eyeButton}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <img src={EyeOpen} />
                  ) : (
                    <img src={EyeClosed} />
                  )}
                </button>
              </div>
              {errors.confirmPassword && (
                <p className={styles.errorText}>{errors.confirmPassword}</p>
              )}
            </div>
          </div>
          <div className={styles.modalFooter}>
            <button
              className={styles.primaryButton}
              onClick={handleResetPassword}
            >
              Set Password
            </button>
            <button className={styles.secondaryButton} onClick={toggle}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default ResetPasswordModal;
