import React, { Component } from "react";
import EyeOpen from "../../assets/icons/EyeOpen.svg";
import EyeClosed from "../../assets/icons/EyeClosed.svg";
import $ from "jquery";
// Utils
import * as API from "../../utils/api";

class ChoosePasswordForm extends Component {
  state = {
    showPassword1: false,
    showPassword2: false,
    input: {
      password: {
        value: "",
        hasError: false,
      },
      confirmPassword: {
        value: "",
        hasError: false,
      },
    },
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { user } = this.props;
    if (this.validateForm()) {
      // $('#sbmt-new-pwd').replaceWith('<div class="spinner"></div>');
      const authToken = this.props.token;
      const userID = this.props.userID;
      let resetPayload = {
        token: authToken,
        userID: userID,
        password_reset: {
          password: this.state.input.password.value,
        },
      };

      if (user.client_admin) {
        API.updateClientPassword(user, this.state.input.password.value).then(
          (data) => {
            if (
              (typeof data.data != "undefined" || data.data != null) &&
              !data.error &&
              data.status_code === 200
            ) {
              const user = {
                first_name: data.data.first_name,
                last_name: data.data.last_name,
                user_id: data.data.id,
                user_auth_token: data.data.auth_token,
                admin: data.data.admin,
                is_employee: data.data.is_employee,
                super_admin: data.data.super_admin,
                onboarding_complete: data.data.accepted_on ? true : false,
                client_admin: data.data.client_admin,
                verified: data.data.verified,
                verified_at: data.data.verified_at,
              };
              this.props.updateUserLogin(user);
              this.props.handleSubmit();
            } else {
              this.props.updateModalState(true, "ERROR", {
                message: data.message,
              });
            }
          }
        );
      } else if (user.is_employee) {
        API.updateEmployeePassword(user, this.state.input.password.value).then(
          (data) => {
            if (
              (typeof data != "undefined" || data != null) &&
              !data.error &&
              data.status_code === 200
            ) {
              const user = {
                first_name: data.first_name,
                last_name: data.last_name,
                user_id: data.id,
                user_auth_token: data.auth_token,
                admin: data.admin,
                is_employee: data.is_employee,
                super_admin: data.super_admin,
                onboarding_complete: data.accepted_on ? true : false,
                client_admin: data.client_admin,
                verified: data.verified,
                verified_at: data.verified_at,
              };
              this.props.updateUserLogin(user);
              this.props.handleSubmit();
            } else {
              this.props.updateModalState(true, "ERROR", {
                message: data.message,
              });
            }
          }
        );
      } else {
        API.passwordReset(null, resetPayload).then((data) => {
          if (
            (typeof data != "undefined" || data != null) &&
            !data.error &&
            data.status_code === 200
          ) {
            this.props.handleSubmit();
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
        });
      }
    }
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    if (newInputState.password.value === "") {
      newInputState.password.hasError = true;
      errorsArePresent = true;
    }

    if (newInputState.confirmPassword.value === "") {
      newInputState.confirmPassword.hasError = true;
      errorsArePresent = true;
    } else if (
      newInputState.confirmPassword.value !== newInputState.password.value
    ) {
      newInputState.confirmPassword.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  clearFormInput = () => {
    this.setState((state) => ({
      ...state,
      input: {
        password: {
          value: "",
          hasError: false,
        },
        confirmPassword: {
          value: "",
          hasError: false,
        },
      },
    }));
  };

  renderErrorMessage(input) {
    let message =
      input === "password"
        ? "Please enter a password"
        : "Please make sure passwords match";

    return <div className='input-error-message'>{message}</div>;
  }

  toggleShowPassword = (type) => {
    return this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };

  render() {
    const { input, showPassword1, showPassword2 } = this.state;

    return (
      <form
        className='sessions-form'
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <div
          className={`input-container${
            input.password.hasError ? " error" : ""
          }`}
        >
          <label>Password</label>
          <input
            name='password'
            type={showPassword1 ? "text" : "password"}
            value={input.password.value}
            onChange={(event) =>
              this.handleInputChange({
                password: { value: event.target.value, hasError: false },
              })
            }
          />
          <button
            type='button'
            className={`eye-button`}
            onClick={() => this.toggleShowPassword("showPassword1")}
          >
            {showPassword1 ? <img src={EyeOpen} /> : <img src={EyeClosed} />}
          </button>
          {input.password.hasError ? this.renderErrorMessage("password") : null}
        </div>

        <div
          className={`input-container${
            input.confirmPassword.hasError ? " error" : ""
          }`}
        >
          <label>Confirm Password</label>
          <input
            name='confirmPassword'
            type={showPassword2 ? "text" : "password"}
            value={input.confirmPassword.value}
            onChange={(event) =>
              this.handleInputChange({
                confirmPassword: { value: event.target.value, hasError: false },
              })
            }
          />
          <button
            type='button'
            className={`eye-button`}
            onClick={() => this.toggleShowPassword("showPassword2")}
          >
            {showPassword2 ? <img src={EyeOpen} /> : <img src={EyeClosed} />}
          </button>
          {input.confirmPassword.hasError
            ? this.renderErrorMessage("confirmPassword")
            : null}
        </div>
        <input
          id='sbmt-new-pwd'
          className='cta mg-button'
          type='submit'
          value='Submit New Password'
        />
      </form>
    );
  }
}

export default ChoosePasswordForm;
