import React, { createContext, useEffect, useState } from "react";
import * as API from "../utils/api";
import { Cookies, withCookies } from "react-cookie";
import { Redirect } from "react-router-dom";

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children, user }) => {
  const cookies = new Cookies();
  const [progressPercent, setProgressPercent] = useState(0);
  let retry = 0;
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  function checkUserPresence() {
    let loggedUser = cookies.get("authUser") || {};
    if (isEmptyObject(loggedUser) && !loggedUser.client_admin) {
      setTimeout(checkUserPresence, 1500);
    } else {
      getOnboardData();
    }
  }

  useEffect(() => {
    checkUserPresence();
  }, []);

  const getOnboardData = async () => {
    if (retry >= 3) {
      setShouldRedirect(true);
      retry = 0;
      return;
    }
    try {
      let loggedUser = cookies.get("authUser") || {};
      let userData = user || loggedUser;
      const data = await API.getOrgDetails(userData);

      if (data && data.status_code === 200) {
        setProgressPercent(
          parseFloat(data.data.organization.onboarding_percentage)
        );
        retry = 0;
      } else if (data && data.status_code === 401) {
        return;
      } else if (data && data.status_code === 500) {
        retry++;
        let loggedUser = cookies.get("authUser") || {};
        if (!isEmptyObject(loggedUser)) {
          return setTimeout(checkUserPresence, 1000);
        }
        return;
      } else {
        retry++;
        setTimeout(getOnboardData, 2000);
      }
    } catch (error) {
      retry++;
      setTimeout(getOnboardData, 2000);
    }
  };

  if (shouldRedirect) {
    cookies.remove("authUser", { path: "/", secure: true });
    cookies.remove("isLoggedIn", { path: "/", secure: true });
    cookies.remove("accessToken", { path: "/", secure: true });
    cookies.remove("refreshToken", { path: "/", secure: true });
    window.location.href = "/login";
  }

  return (
    <OnboardingContext.Provider value={{ progressPercent, setProgressPercent }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContext;
