import React, { Component } from "react";

// Components
import Modal from "../Modal";
import AppCanvasLoader from "../../AppCanvasLoader";
import * as API from "../../../../utils/api";

class ShowImageModal extends Component {
  state = {
    image: "",
    showLoader: true,
    isPDF: false,
  };

  componentDidMount() {
    const { optionalProps } = this.props;
    let { user, doc_id, image, isOpenApi } = optionalProps;
    if (isOpenApi && doc_id) {
      this.getExpressAttachedDocuement(doc_id);
    } else if (image) {
      this.setImageData(image);
    } else {
      if (doc_id && user) {
        this.getAttachedDocuement(user, doc_id);
      }
    }
  }

  //get attached doc for express payment screen. open api
  getExpressAttachedDocuement = (doc_id) => {
    API.getExpressAttachedDocuement(doc_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.setImageData(data.data);
        }
      }
    });
  };

  getAttachedDocuement = (user, doc_id) => {
    let { optionalProps } = this.props;
    let { isConsumer, isConsumerPayment } = optionalProps;
    let fromURI = "debts";
    if (
      this.props.optionalProps.from &&
      this.props.optionalProps.from === "credit_memo"
    ) {
      fromURI = "credits";
    }
    if (optionalProps.hasOwnProperty("isConsumer") && isConsumer) {
      API.getAttachedDocuementForConsumer(user, doc_id, fromURI).then(
        (data) => {
          if (data && data.status_code && data.status_code == 200) {
            if (data.data) {
              this.setImageData(data.data);
            }
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
              showLoader: false,
            }));
          }
        }
      );
    } else if (
      optionalProps.hasOwnProperty("isConsumerPayment") &&
      isConsumerPayment
    ) {
      API.getDownloadConsumerPaymentPlan(user, doc_id).then((data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            this.setImageData(data.data);
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      });
    } else if (optionalProps.from && optionalProps.from === "payment_plan") {
      fromURI = "payment_plan_document";
      API.getDownloadPaymentDocument(user, doc_id, fromURI).then((data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            this.setImageData(data.data);
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      });
    } else {
      API.getAttachedDocuement(user, doc_id, fromURI).then((data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            this.setImageData(data.data);
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      });
    }
  };

  setImageData = (image) => {
    let type = this.props.optionalProps.type;
    if (
      type &&
      !(image.includes("data:application") || image.includes("data:image"))
    ) {
      let i = type.lastIndexOf(".");
      if (i >= 0) {
        type = type.substring(i + 1);
        let extension;
        switch (type) {
          case "pdf":
            extension = "data:application/pdf;base64,";
            break;
          case "jpeg":
            extension = "data:image/jpeg;base64,";
            break;
          case "png":
            extension = "data:image/png;base64,";
            break;
          default:
            extension = "data:image/jpg;base64,";
            break;
        }
        this.setState((state) => ({
          ...state,
          image: extension + image,
          showLoader: false,
        }));
      } else {
        this.setState({ showLoader: false });
      }
    } else {
      this.setState((state) => ({
        ...state,
        image: image,
        isPDF: true,
        showLoader: false,
      }));
    }
  };

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let { showLoader, image, isPDF } = this.state;
    let { optionalProps } = this.props;
    let flag =
      optionalProps &&
      optionalProps.arrange_pdf &&
      image.indexOf("application/pdf") !== -1
        ? true
        : false;

    return (
      <Modal
        title={
          optionalProps.doc_name
            ? optionalProps.doc_name
            : optionalProps.invoiceId
            ? "Invoice # " + optionalProps.invoiceId
            : "MakeGood Invoice PDF Preview"
        }
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses={`add-account-modal send-new-mail-title mail-view-detail${
          flag ? " remove-padding" : ""
        }`}
      >
        <form>
          <div
            className={`${flag ? "" : "scrollbar"}`}
            style={
              flag
                ? { height: "auto" }
                : {
                    height: window.innerHeight * 0.7,
                    overflow: "auto",
                    paddingBottom: 50,
                  }
            }
          >
            <div
              className='object-list-empty-state img-align'
              style={{ overflowY: "visible" }}
            >
              {showLoader ? (
                <AppCanvasLoader />
              ) : image || optionalProps.image ? (
                image.indexOf("application/pdf") !== -1 ||
                (optionalProps.fileType && optionalProps.fileType === "PDF") ||
                isPDF ? (
                  <div>
                    <object
                      data={image ? image : optionalProps.image}
                      type='application/pdf'
                      style={{ width: `100%`, height: `500px` }}
                      title={optionalProps.type ? optionalProps.type : ""}
                    >
                      <embed
                        src={image ? image : optionalProps.image}
                        type='application/pdf'
                        title={optionalProps.type ? optionalProps.type : ""}
                      />
                    </object>
                  </div>
                ) : (
                  <div>
                    {image ? (
                      <img
                        alt='document'
                        src={image}
                        height='100%'
                        width='100%'
                        title={optionalProps.type ? optionalProps.type : ""}
                      />
                    ) : (
                      "No Address Document Available."
                    )}
                  </div>
                )
              ) : (
                <div>No Document Available</div>
              )}
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

export default ShowImageModal;
