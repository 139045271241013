import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import * as API from "../../utils/api";
import { validateEmail, errorMessage, showToast } from "../../utils/helpers";
import EyeOpen from "../../assets/icons/EyeOpen.svg";
import EyeClosed from "../../assets/icons/EyeClosed.svg";

const LoginForm = ({ updateModalState, updateUserLogin, primary_color }) => {
  const [input, setInput] = useState({
    email: { value: "", hasError: false },
    password: { value: "", hasError: false },
  });
  const [companyData, setCompanyData] = useState({});
  const [accessError, setAccessError] = useState(false);
  const [error, setError] = useState({ hasAnyError: false, statusCode: 200 });
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    getOrganizationData();
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");
    if (user) {
      setInput((prev) => ({
        ...prev,
        email: { value: user, hasError: false },
      }));
    }
  }, []);

  const getOrganizationData = async () => {
    const data = await API.getOrganizationData();
    setCompanyData(data);
  };

  const handleInputChange = (field, value) => {
    setInput((prev) => ({
      ...prev,
      [field]: { value, hasError: false },
    }));
  };

  const validateForm = () => {
    let errorsArePresent = false;
    const newInput = { ...input };

    if (!validateEmail(newInput.email.value)) {
      newInput.email.hasError = true;
      errorsArePresent = true;
    }
    if (!newInput.password.value) {
      newInput.password.hasError = true;
      errorsArePresent = true;
    }

    setInput(newInput);
    return !errorsArePresent;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const userCandidate = {
      email: input.email.value,
      password: input.password.value,
      organization_key: companyData.organization_key,
    };

    API.setOauthObj(userCandidate);
    try {
      const data = await API.login(userCandidate);
      if (data && !data.error) {
        if (!data.errors) {
          if (
            data.accounting_system === "quickbooks_online" &&
            !data.is_qb_connected
          ) {
            updateModalState(true, "ERROR", {
              modalErr: "Toast",
              message:
                "Your organization is not connected to QuickBooks Online. Please reconnect to ensure seamless integration.",
            });
          }
          updateUserLogin({
            ...data,
            onboarding_complete: !!data.accepted_on,
          });
        } else {
          setAccessError(true);
          let errorData = data.errors.split(".");
          errorData.shift();
          setErrorMsg(errorData);
        }
      } else {
        setError({ hasAnyError: true, statusCode: data.status || 500 });
        setErrorMsg(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (error.hasAnyError) {
    if (error.statusCode === 500) {
      showToast();
      setError({ hasAnyError: false, statusCode: 200 });
    } else if (error.statusCode === 401) {
      return <Redirect to='/sign-out' />;
    } else {
      updateModalState(true, "OTHER_ERROR", true);
      return <Redirect to='/sign-out' />;
    }
  }

  return (
    <div>
      {accessError && (
        <div className='input-error-message'>
          {errorMsg || "Invalid email or password"}
        </div>
      )}
      <form className='sessions-form' onSubmit={handleSubmit}>
        <div
          className={`input-container${input.email.hasError ? " error" : ""}`}
        >
          <label>Email Address</label>
          <input
            name='email'
            type='email'
            placeholder='you@youremail.com'
            value={input.email.value}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        </div>
        <div
          className={`input-container${
            input.password.hasError ? " error" : ""
          }`}
        >
          <label>Password</label>
          <div className='password-input-container'>
            <input
              name='password'
              type={showPassword ? "text" : "password"}
              placeholder='password'
              value={input.password.value}
              onChange={(e) => handleInputChange("password", e.target.value)}
            />
            <button
              type='button'
              className='eye-button'
              onClick={() => setShowPassword(!showPassword)}
            >
              <img
                src={showPassword ? EyeOpen : EyeClosed}
                alt='Toggle password visibility'
              />
            </button>
            <Link className='forgot-password-link' to='/reset-password'>
              Forgot Password?
            </Link>
          </div>
        </div>
        <input
          className='cta mg-button-common'
          type='submit'
          value='Login'
          disabled={isLoading}
          style={{
            backgroundColor: primary_color,
            borderColor: primary_color,
            margin: "2.25rem 0",
          }}
        />
      </form>
    </div>
  );
};

export default LoginForm;
