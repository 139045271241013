import React, { useState, useEffect } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { withRouter } from "react-router-dom";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";

const TeamMemberContactPreferences = ({
  user,
  employees,
  selected_contacts = [],
  hideModal,
  handleClose,
  updateIsAllClient,
  directUpdate,
}) => {
  const [consumers, setConsumers] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState(selected_contacts);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [searchEmpText, setSearchEmpText] = useState("");

  useEffect(() => {
    if (user) fetchSelectedContacts(false);
    initializeSelectedContacts();
  }, [user, employees]);

  const initializeSelectedContacts = () => {
    if (employees) {
      const updatedContacts = employees
        .filter((item) => item.is_assigned)
        .map((item) => item.user_id);
      setSelectedContacts(updatedContacts);
      setIsSelectAll(updatedContacts.length === employees.length);
    }
  };

  const fetchSelectedContacts = async (reload) => {
    setShowLoader(true);
    try {
      const data = await API.getSelectedContactsunassigned(user, offset);
      if (data && !data.error) {
        const newConsumers = reload ? [...consumers, ...data.data] : data.data;
        newConsumers.forEach((item) => {
          if (selectedContacts.includes(item.user_id)) {
            item.is_selected = true;
          }
        });
        setConsumers(newConsumers);
        setIsLoading(false);
        setShowLoader(false);
      } else {
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const handleCheckboxToggle = (isChecked, userId) => {
    setSelectedContacts((prev) => {
      const updatedContacts = isChecked
        ? [...prev, userId]
        : prev.filter((id) => id !== userId);

      setIsSelectAll(updatedContacts.length === consumers.length);

      return updatedContacts;
    });
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setIsSelectAll(isChecked);
    if (isChecked) {
      const allIds = consumers.map((consumer) => consumer.user_id);
      setSelectedContacts(allIds);
      if (!directUpdate) updateIsAllClient(true);
    } else {
      setSelectedContacts([]);
      if (!directUpdate) updateIsAllClient(false);
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    hideModal(selectedContacts, isSelectAll);
  };

  const handleSearchChange = (e) => {
    setSearchEmpText(e.target.value);
  };

  const handleContainerOnBottom = () => {
    setOffset((prevOffset) => prevOffset + 1);
    fetchSelectedContacts(true);
  };

  const getFilteredList = () => {
    return (employees.length ? employees : consumers)
      .filter((item) =>
        item.display_name
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(searchEmpText.replace(/\s+/g, "").toLowerCase())
      )
      .map((item) => ({
        primaryColumn: {},
        secondaryColumns: [
          {
            title: (
              <input
                type='checkbox'
                name={item.user_id}
                key={item.user_id}
                checked={selectedContacts.includes(item.user_id)}
                style={{ WebkitAppearance: "checkbox", width: "20px" }}
                onChange={(e) =>
                  handleCheckboxToggle(e.target.checked, item.user_id)
                }
              />
            ),
            opClassesPar: "task-checkbox",
          },
          { title: item.display_name || "" },
        ],
        fullRowIsClickable: false,
        optionalRowClasses: "object-row-inner-padding",
      }));
  };

  return (
    <Modal
      title='Assign Accounts'
      closeOnClickOutside={true}
      hideModal={handleSubmit}
      handleModalClose={handleClose}
      style={{ maxWidth: `35em`, paddingBottom: "1em" }}
      optionalClasses='scroll task-popup-sp'
      titleClasses='modal-header'
    >
      {isLoading ? (
        <AppCanvasLoader />
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", height: "80%" }}
        >
          <div>
            <input
              style={{ padding: "12px", marginBottom: "1rem" }}
              placeholder='Search Accounts'
              value={searchEmpText}
              onChange={handleSearchChange}
            />
            <div
              className='contact-count'
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
                alignItems: "center",
              }}
            >
              {`Selected Contacts: ${selectedContacts.length}`}
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <input
                  type='checkbox'
                  checked={isSelectAll}
                  style={{ WebkitAppearance: "checkbox", width: "20px" }}
                  onChange={handleSelectAll}
                />
                <span>Select All</span>
              </div>
            </div>
            {consumers.length > 0 && (
              <BottomScrollListener
                onBottom={handleContainerOnBottom}
                debounce={0}
                offset={50}
              >
                {(scrollRef) => (
                  <div>
                    <ObjectDebtList
                      data={{ headings: [], columns: getFilteredList() }}
                      scrollRef={scrollRef}
                      removePadding={true}
                      showLoader={showLoader}
                    />
                  </div>
                )}
              </BottomScrollListener>
            )}
            {hasError && (
              <div
                className='input-container error'
                style={{ marginBottom: "1.5em", marginTop: "-1em" }}
              >
                <div className='input-error-message'>Please Select Contact</div>
              </div>
            )}
          </div>
          <div
            className='modal-actions'
            style={{
              position: "sticky",
              bottom: -17,
              background: "#fff",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            <a
              className='cta mg-brand2-color'
              onClick={handleSubmit}
              style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
            >
              Done
            </a>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withRouter(TeamMemberContactPreferences);
