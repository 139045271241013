import React, { useRef } from "react";
import styles from "./reports.module.scss";
import shortid from "shortid";
import { Table } from "reactstrap";
import moment from "moment";
import { getDollarAmount } from "../../../utils/helpers";
import { VariableSizeList as List } from "react-window";

const headings = [
  { invoiceKey: "txn_date", paymentKey: "date", label: "Date" },
  { invoiceKey: "invoice", paymentKey: "payment", label: "Transaction Type" },
  { invoiceKey: "descriptions", paymentKey: null, label: "Memo/Description" },
  { invoiceKey: "", paymentKey: "payment_type", label: "Payment Method" },
  { invoiceKey: "invoice_number", paymentKey: null, label: "Num" },
  {
    invoiceKey: "total_amount_with_tax",
    paymentKey: "amount",
    label: "Amount",
  },
  { invoiceKey: "", paymentKey: "processing_fee", label: "Processing Fee" },
];
const InvoicesPaymentsReport = (props) => {
  const { data } = props;
  const listRef = useRef(null);

  const calculateCounts = (data) => {
    const dataLength = data.length;
    const listLength = data.reduce((sum, item) => sum + item.list.length, 0);
    const paymentTransactionsLength = data.reduce(
      (sum, item) =>
        sum +
        item.list.reduce(
          (acc, invoice) => acc + invoice.payment_transactions.length,
          0
        ),
      0
    );
    const totalRows = dataLength + listLength + paymentTransactionsLength;

    return { totalRows, dataLength, listLength, paymentTransactionsLength };
  };

  const handleClick = (type, id) => {
    const redirect = {
      Account: `/client/accounts/${id}`,
      Invoice: `/client/invoices/${id}`,
    };
    let path = redirect[type];
    props.props.history.push(path);
  };

  const getItemSize = (index) => {
    if (!data || !data.items || !Array.isArray(data.items)) {
      return 35; // Default row size if data is missing
    }

    const { items } = data;
    let rowIndex = 0;

    for (let i = 0; i < items.length; i++) {
      const { list: userList } = items[i] || {}; // Ensure `items[i]` is not undefined

      if (rowIndex === index) return 35; // User row
      rowIndex++;

      if (!userList || !Array.isArray(userList) || userList.length === 0) {
        if (rowIndex === index) return 35; // "No data found" row
        rowIndex++;
      } else {
        for (let j = 0; j < userList.length; j++) {
          const item = userList[j] || {}; // Ensure `item` is not undefined

          const transactions = item.payment_transactions || [];
          for (let k = 0; k < transactions.length; k++) {
            if (rowIndex === index) return 35; // Transaction row
            rowIndex++;
          }

          if (rowIndex === index) {
            const descriptions = Array.isArray(item.descriptions)
              ? item.descriptions
              : [];
            const extraHeight = descriptions.length * 20; // Adjust height dynamically
            return 35 + extraHeight; // Base + description height
          }
          rowIndex++;
        }
      }
    }

    return 35; // Default size if index is out of range
  };

  return (
    <div>
      <Table striped hover className={styles.agingDetailReport}>
        <thead className={styles.headerFix}>
          <tr>
            {headings &&
              headings.map((header, index) => (
                <th
                  key={`${shortid.generate()}${index}`}
                  className={styles.headingStyle}
                  style={{ borderTop: "none" }}
                >
                  {header.label}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          <List
            height={500}
            itemCount={1500}
            ref={listRef}
            // itemCount={calculateCounts(data).totalRows}
            itemSize={getItemSize}
            width={"101%"}
            itemData={{
              items: data,
              handleClick: handleClick,
            }}
          >
            {renderRow}
          </List>
        </tbody>
      </Table>
    </div>
  );
};

const renderRow = ({ index, style, data: allData }) => {
  const { items: dataItems, handleClick } = allData;
  let rowIndex = 0;

  const formatListToString = (list) => {
    let charCount = 0;

    return list.reduce((acc, item, index) => {
      if (charCount + item.length > 50) {
        return acc; // Stop adding if 50 characters are reached
      }

      charCount += item.length;
      return acc + (index ? ", " : "") + item;
    }, "");
  };

  for (let i = 0; i < dataItems.length; i++) {
    const rowData = dataItems[i];
    const { user_name: user, key: user_id, list: data } = rowData;

    // User row
    if (rowIndex === index) {
      return (
        <tr
          key={`user-${user_id}`}
          onClick={() => handleClick("Account", user_id)}
          style={{ cursor: "pointer", ...style }}
        >
          <td
            colSpan={7}
            className={`${styles.rowData} ${styles.title}`}
            style={{ width: "fit-content" }}
          >
            {user}
          </td>
        </tr>
      );
    }
    rowIndex++;

    // No data row
    if (!data || data.length === 0) {
      if (rowIndex === index) {
        return (
          <tr key={`nodata-${user_id}`} style={style}>
            <td
              colSpan={headings.length}
              className={styles.rowData}
              style={{ textAlign: "center" }}
            >
              No data found
            </td>
          </tr>
        );
      }
      rowIndex++;
    } else {
      for (let j = 0; j < data.length; j++) {
        const item = data[j];

        // Transaction rows
        for (let k = 0; k < item.payment_transactions.length; k++) {
          const transaction = item.payment_transactions[k];

          if (rowIndex === index) {
            return (
              <tr
                key={`transaction-${transaction.id}`}
                className={`${styles.rowData} row-total`}
                style={style}
              >
                {headings.map((heading) => {
                  let value = transaction[heading.paymentKey] || "";
                  if (heading.label === "Transaction Type") {
                    value =
                      transaction.payment_type === "Credit Memo"
                        ? "Credit Memo Payment"
                        : "Payment";
                  } else if (heading.label === "Date") {
                    value = moment
                      .utc(transaction[heading.paymentKey])
                      .format("YYYY-MM-DD");
                  } else if (
                    heading.label === "Amount" ||
                    heading.label === "Processing Fee"
                  ) {
                    value = getDollarAmount(transaction[heading.paymentKey]);
                  }
                  return (
                    <td
                      key={heading.paymentKey}
                      className={styles.rowData}
                      style={{
                        whiteSpace:
                          heading.label === "Transaction Type" ? "nowrap" : "",
                      }}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          }
          rowIndex++;
        }

        // Invoice row
        if (rowIndex === index) {
          return (
            <tr
              key={`invoice-${item.invoice_id}`}
              className={`${styles.rowData} row-total`}
              onClick={() => handleClick("Invoice", item.invoice_id)}
              style={{ ...style, cursor: "pointer", minHeight: "fit-content" }}
            >
              {headings.map((heading) => {
                let value = item[heading.invoiceKey] || "";
                if (heading.label === "Transaction Type") {
                  value = "Invoice";
                }
                if (
                  heading.invoiceKey === "descriptions" &&
                  Array.isArray(value) &&
                  value.length > 0
                ) {
                  value = (
                    <p
                      style={{
                        color: "black",
                        cursor: "pointer",
                      }}
                      title={value.join("\n")}
                    >
                      {formatListToString(value)}...
                    </p>
                  );
                } else if (heading.label === "Amount") {
                  value = getDollarAmount(item[heading.invoiceKey]);
                }
                return (
                  <td key={heading.invoiceKey} className={styles.rowData}>
                    {value}
                  </td>
                );
              })}
            </tr>
          );
        }
        rowIndex++;
      }
    }
  }

  return null; // Return null if index exceeds the available data
};

export default InvoicesPaymentsReport;
