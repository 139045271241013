import React, { Component } from "react";
import PropTypes from "prop-types";

class MenuItem extends Component {
  static propTypes = {
    handleClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
  };

  render() {
    const {
      handleClick,
      children,
      dataBehavior,
      dataTarget,
      optionalClasses,
      dataThreshold,
      verified,
      startMails,
    } = this.props;
    const isDisable = verified || startMails;
    return (
      <li
        className={`menu-item ${optionalClasses ? " " + optionalClasses : ""}`}
        style={
          isDisable
            ? { cursor: "none", pointerEvents: "none" }
            : { cursor: "pointer", margin: 0 }
        }
        onClick={isDisable ? "" : handleClick}
        data-behavior={dataBehavior}
        data-target={dataTarget}
        data-threshold={JSON.stringify(dataThreshold)}
      >
        {children}
      </li>
    );
  }
}

export default MenuItem;
