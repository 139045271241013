import React, { Component } from "react";
import * as API from "../../../utils/api";

// Packages
import Select from "react-select";

// Components
import Panel from "../../App/Panel";

// Utils
import { validateEmail } from "../../../utils/helpers";

class AdminAddAdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalProps: {},
      duplicate: {
        isFound: false,
        id: "",
      },
      input: {
        first_name: {
          value: "",
          hasError: false,
        },
        last_name: {
          value: "",
          hasError: false,
        },
        email: {
          value: "",
          hasError: false,
        },
        role: {
          value: "",
          hasError: false,
        },
      },
    };
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleShowModal = (message) => {
    this.setState({
      isModalOpen: true,
      modalProps: {
        message: message,
        isCancelCallback: true,
        closeOnClickOutside: false,
        btnName1: "Proceed",
        btnName2: "Cancel",
        confirmClick: (e) => this.handleProceed(e),
        cancelClick: this.handleCancel,
      },
    });
  };

  handleProceed = (e) => {
    this.setState({ isModalOpen: false }, () => {
      if (this.state.save_and_send) {
        this.handleSubmit(e, true);
      } else {
        this.handleSubmit(e);
      }
    });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  verifyUser = async () => {
    let email = this.state.input.email.value;
    const { user } = this.props;
    try {
      const res = await API.verifyUserExistence(user, email);
      return res;
    } catch (error) {
      console.error("Error verifying user:", error);
      return { data: [] };
    }
  };

  handleFormSubmission = async (event) => {
    event.preventDefault();
    const res = await this.verifyUser();

    if (this.validateForm()) {
      if (res && res.data && res.data.length > 0) {
        this.setState({
          duplicate: {
            isFound: true,
            id: res.data[0].id,
          },
        });
        let message;

        // if (this.state.user === res.data[0].role) {
        message = `This email is already associated with another organization with the role of ${res.data[0].role}. Would you like to proceed or change the email?`;
        // } else {
        //   message = `This email is already in use in another organization with the role of ${res.data[0].role}. Would you like to proceed or change the email? Updating will modify the user's role in a different organization.`;
        // }

        this.handleShowModal(message);
      } else {
        this.handleSubmit(event);
      }
    }
  };
  handleSubmit = (event) => {
    // event.preventDefault();

    const { input } = this.state;

    let newAdminUser = {
      first_name: input.first_name.value,
      last_name: input.last_name.value,
      email_id: input.email.value,
      organization_id: this.props.organization.id,
    };
    if (this.state.duplicate.isFound) {
      newAdminUser.admin_id = this.state.duplicate.id;
    }

    this.props.handleFormSubmission(newAdminUser);
    this.resetAndCloseForm();
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;
    if (!validateEmail(newInputState.email.value)) {
      newInputState.email.hasError = true;
      errorsArePresent = true;
    }
    if (
      newInputState.first_name.value === "" ||
      newInputState.last_name.value === ""
    ) {
      newInputState.first_name.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  resetAndCloseForm = () => {
    this.resetForm();
    this.props.closeForm();
  };

  resetForm = () => {
    this.setState((state) => ({
      ...state,
      input: {
        name: {
          value: "",
          hasError: false,
        },
        email: {
          value: "",
          hasError: false,
        },
        // role: {
        //   value: "",
        //   hasError: false
        // }
      },
    }));
  };

  renderErrorMessage(type) {
    return type === "name" ? (
      <div className='input-error-message'>Please enter a name</div>
    ) : (
      <div className='input-error-message'>
        Please enter a valid email address
      </div>
    );
  }

  render() {
    let { input } = this.state;
    return (
      <form
        className='admin-add-admin-form'
        onSubmit={(event) => this.handleFormSubmission(event)}
      >
        <Panel>
          <div
            className={`input-container${
              input.first_name.hasError || input.last_name.hasError
                ? " error"
                : ""
            }`}
          >
            <label htmlFor='name'>Admin's Name</label>
            <div className='input-container multi-input-field multi-input-admin'>
              <input
                className='multi-input-field-input'
                name='name'
                type='text'
                placeholder='First Name'
                value={input.first_name.value}
                onChange={(event) =>
                  this.handleInputChange({
                    first_name: { value: event.target.value, hasError: false },
                  })
                }
              />
              <input
                className='multi-input-field-input'
                name='name'
                type='text'
                placeholder='Last Name'
                value={input.last_name.value}
                onChange={(event) =>
                  this.handleInputChange({
                    last_name: { value: event.target.value, hasError: false },
                  })
                }
              />
            </div>
            {input.first_name.hasError || input.last_name.hasError
              ? this.renderErrorMessage("name")
              : null}
          </div>

          <div
            className={`input-container${input.email.hasError ? " error" : ""}`}
          >
            <label htmlFor='email'>Admin's Email</label>
            <input
              name='email'
              type='email'
              placeholder='Email'
              value={input.email.value}
              onChange={(event) =>
                this.handleInputChange({
                  email: { value: event.target.value, hasError: false },
                })
              }
            />
            {input.email.hasError ? this.renderErrorMessage("email") : null}
          </div>
          {/* <div className="select-container">
            <label htmlFor="email">
              Role
            </label>
            <Select
              inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
              className="form-select"
              name="role"
              value={input.role.value}
              placeholder="Select Role"
              options={ [
                {"value":"Admin","label":"Admin"},
                {"value":"Super","label":"Super Admin"}
              ] }
              resetValue=""
              onChange={event => this.handleInputChange({role: {value: event ? event.value : '', hasError: false}})}
            />
          </div> */}
          <div className='admin-add-admin-form-actions'>
            <div className='admin-add-admin-form-actions-content'>
              <input
                type='submit'
                value='+ Add Admin'
                className='cta mg-brand2-color'
              />
              <a className='text-link' onClick={() => this.resetAndCloseForm()}>
                Cancel
              </a>
            </div>
          </div>
        </Panel>
        {this.state.isModalOpen && (
          <ConfirmAlertModal
            optionalProps={this.state.modalProps}
            hideModal={this.hideModal}
          />
        )}
      </form>
    );
  }
}

export default AdminAddAdminForm;

const ConfirmAlertModal = ({ optionalProps, hideModal }) => {
  const handleClickOutside = () => {
    if (optionalProps.closeOnClickOutside) {
      hideModal();
    }
  };

  const {
    message,
    title,
    methodData,
    isCancelCallback,
    btnName1,
    btnName2,
    confirmClick,
    cancelClick,
  } = optionalProps;

  return (
    <div className='confirm-modal' onClick={handleClickOutside}>
      <div className='modal-msg'>{message}</div>
      <div className='confirm-btns'>
        <button
          className='yes-btn'
          onClick={(event) => {
            confirmClick(methodData);
            hideModal();
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          {btnName1 || "YES"}
        </button>
        {isCancelCallback ? (
          <button
            className='cancel-btn'
            onClick={() => {
              cancelClick(methodData);
              hideModal();
            }}
          >
            NO
          </button>
        ) : (
          <button className='cancel-btn' onClick={hideModal}>
            {btnName2 || "NO"}
          </button>
        )}
      </div>
    </div>
  );
};
