import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//Packages
import MaskedTextInput from "react-text-mask";

// Utils
import * as API from "../../utils/api";

import {
  getStrippedNumberFromString,
  networkMessage,
} from "../../utils/helpers";

class OnboardingCreateAccountForm extends Component {
  state = {
    input: {
      confirmationCode: {
        value: "",
        hasError: false,
      },
    },
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm("confirmationCode")) {
      const smsCode = this.state.input.confirmationCode.value;

      API.confirmAccount(this.props.user, smsCode).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.errors) {
          //this.props.history.push("/onboarding/address")
          this.props.history.push("/onboarding/user-agreement");
        } else {
          let newInputState = { ...this.state.input };
          newInputState.confirmationCode.hasError = true;
          this.setState((state) => ({
            ...state,
            input: newInputState,
          }));
        }
      });
    }
  };

  validateForm = (formName) => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    let confirmationCode = getStrippedNumberFromString(
      newInputState.confirmationCode.value
    );

    if (confirmationCode.toString().length !== 6) {
      newInputState.confirmationCode.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    return (
      <div className='input-error-message'>
        The code you entered is incorrect. <br /> Please try again.
      </div>
    );
  }

  resendConfirmationCode = () => {
    this.props.resendConfirmationCode(true);
  };

  render() {
    const { input } = this.state;

    return (
      <div className='sessions-form-container'>
        <form
          className='sessions-form'
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <div
            className={`input-container${
              input.confirmationCode.hasError ? " error" : ""
            }`}
          >
            <MaskedTextInput
              name='confirmationCode'
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
              placeholder='Confirmation Code'
              placeholderChar={"\u2000"}
              guide={true}
              type='text'
              value={input.confirmationCode.value}
              onChange={(event) =>
                this.handleInputChange({
                  confirmationCode: {
                    value: event.target.value,
                    hasError: false,
                  },
                })
              }
            />
            {input.confirmationCode.hasError
              ? this.renderErrorMessage("confirmationCode")
              : null}
          </div>
          <input
            className='cta mg-button'
            type='submit'
            value='Confirm Account »'
          />
        </form>
        <div className='sessions-form-note center'>
          <span>Didn't receive a confirmation? </span>
          <a
            className='text-link mg-text-link'
            onClick={this.resendConfirmationCode}
          >
            Resend Now
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(OnboardingCreateAccountForm);
