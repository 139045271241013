import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import ArrowUp from "../../assets/icons/ArrowUp.svg";
import ArrowDown from "../../assets/icons/ArrowDown.svg";

const GroupedSelect = ({
  options,
  onChange,
  searchTerm,
  opendDropdown,
  optionsLoading,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [collapsedGroups, setCollapsedGroups] = useState([]);
  const selectContainerRef = useRef(null);

  const handleSelection = (group, value) => {
    if (onChange) onChange(group, value);
    setIsDropdownOpen(false);
    setCollapsedGroups([]);
  };

  const handleOutsideClick = (event) => {
    if (
      selectContainerRef.current &&
      !selectContainerRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (opendDropdown || searchTerm !== "") {
      setIsDropdownOpen(true);
    }
  }, [opendDropdown, searchTerm]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleGroupCollapse = (groupIndex) => {
    setCollapsedGroups((prev) =>
      prev.includes(groupIndex)
        ? prev.filter((index) => index !== groupIndex)
        : [...prev, groupIndex]
    );
  };

  const filteredOptions =
    options &&
    options
      .map((group, index) => ({
        label: group.label,
        options: group.options && group.options,
        index,
      }))
      .filter((group) => group.options && group.options.length > 0);

  return (
    <div className='grouped-select-container' ref={selectContainerRef}>
      {isDropdownOpen && searchTerm !== "" && (
        <div className='grouped-select-dropdown'>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((group) => (
              <div key={group.index} className='group'>
                <div
                  className='group-label'
                  onClick={() => toggleGroupCollapse(group.index)}
                >
                  {group.label}
                  <img
                    src={
                      collapsedGroups.includes(group.index)
                        ? ArrowUp
                        : ArrowDown
                    }
                  />
                </div>
                {!collapsedGroups.includes(group.index) && (
                  <div className='options-container'>
                    {group.options.map((option, index) => (
                      <div
                        key={`${option.label} ${index}`}
                        className='option'
                        onClick={() => handleSelection(group.label, option)}
                      >
                        <span>{highlightMatch(option.label, searchTerm)}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : optionsLoading ? (
            <div className='spinner'></div>
          ) : (
            <div className='no-options'>No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

const highlightMatch = (text, term) => {
  if (!term) return <span className='genText'>{text}</span>;

  const escapedTerm = term.replace(/[*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(`(${escapedTerm})`, "gi");

  const processTextPart = (part, index) => {
    if (regex.test(part)) {
      return part.split(regex).map((subPart, subIndex) =>
        regex.test(subPart) ? (
          <span key={`${index}-${subIndex}`} className='highlight'>
            {subPart}
          </span>
        ) : (
          <span key={`${index}-${subIndex}`} className='genText'>
            {subPart}
          </span>
        )
      );
    }
    return (
      <span key={index} className='genText'>
        {part}
      </span>
    );
  };

  const parts = text.split(/Error:/i);
  if (parts.length > 1) {
    const [beforeError, afterError] = parts;
    return (
      <>
        {beforeError.split(/(\d{1,3}(?:,\d{3})*)/).map(processTextPart)}
        <br />
        <br />
        <span className='address'>{afterError}</span>
      </>
    );
  }

  return text.split(/(\d{1,3}(?:,\d{3})*)/).map(processTextPart);
};

export default GroupedSelect;
