import React, { useEffect, useState } from "react";
import MGLogo1 from "../../../assets/icons/MG-logo.png";
import styles from "./style.module.scss";
import * as API from "../../../utils/api";
// import CheckCircle from "../../../assets/icons/CheckCircle.svg";
import { ReactComponent as CheckCircleIcon } from "../../../assets/icons/CheckCircle.svg";

const Index = (props) => {
  const [orgItems, setOrgItems] = useState(null);
  const [newUser, setNewUser] = useState(null);

  const getOrgList = () => {
    API.getOrganizationList(props.user).then((res) => {
      if (res.status_code === 200) {
        setOrgItems(res.organizations);
        setNewUser(res.user);
      } else {
      }
    });
  };

  useEffect(() => {
    getOrgList();
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <img src={MGLogo1} alt='Company Logo' style={{ width: "15rem" }} />
        </div>
        <div
          className='mg-head-bold'
          style={{ textAlign: "center", marginBottom: "1rem" }}
        >
          Choose your organization
        </div>
        <div className={styles.tabsContainer}>
          {orgItems ? (
            orgItems.map((item) => (
              <Tab
                key={item.name}
                orgItems={orgItems}
                name={item.name}
                url={item.website_url}
                history={props.history}
                cookies={props.cookies}
                user={props.user}
                orgKey={item.org_key}
                openID={newUser && newUser.openid_id}
                email={newUser && newUser.email}
                idToken={item.id_token}
              />
            ))
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ fontSize: "18px", fontWeight: 400 }}>
                {"No organizations found"}
              </p>
            </div>
          )}
        </div>
        <div className={styles.signInText}>Sign In With Different Account</div>
      </div>
    </div>
  );
};

const Tab = (props) => {
  const currentUrl = window.location.origin;
  const handleClick = async () => {
    let payloadToken = {
      organization_key: props.orgKey,
      id_token: props.idToken,
      openid_id: props.openID,
      email: props.email,
    };

    localStorage.setItem("newOrgData", JSON.stringify(payloadToken));

    try {
      // Sign out the user
      await API.signout(props.user);

      // Remove cookies
      props.cookies.remove("authUser", { path: "/", secure: true });
      props.cookies.remove("isLoggedIn", { path: "/", secure: true });
      props.cookies.remove("accessToken", { path: "/", secure: true });
      props.cookies.remove("refreshToken", { path: "/", secure: true });

      window.location.href = `${props.url}/login?username=${btoa(
        payloadToken.email
      )}&openid_id=${btoa(payloadToken.openid_id)}&org_key=${btoa(
        payloadToken.organization_key
      )}&id_token=${btoa(payloadToken.id_token)}&is_switch_account=${true}`;
    } catch (error) {
      console.error("Error during signout or SSO process:", error);
    }
  };

  return (
    <div
      className={`${styles.tabContainer} ${
        props.url === currentUrl && styles.active
      }`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={() => {
        if (props.idToken) {
          handleClick();
        }
      }}
    >
      <span>{`${props.name}`}</span>
      {props.url === currentUrl && (
        <span>
          <CheckCircleIcon width={24} height={24} />
        </span>
      )}
    </div>
  );
};

export default Index;
