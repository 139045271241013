import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Packages
import moment from "moment";
import currencyFormatter from "currency-formatter";
import shortid from "shortid";

// Components
import DropdownOverflowMenu from "./DropdownOverflowMenu";
import MenuItem from "../MenuItem";
import ExpanderTable from "./ExpanderTable";

class ObjectRow extends Component {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    appName: PropTypes.string,
  };

  state = {
    rowType: "default",
    expanded: false,
  };

  componentDidMount() {
    let { rowData } = this.props;

    this.setRowType(rowData.rowType);
  }

  setRowType(rowType) {
    this.setState((state) => ({
      ...state,
      rowType: rowType,
    }));
  }

  handleExpanderClick = (event) => {
    event.preventDefault();
    let { rowType, expanded } = this.state;

    if (rowType === "expander") {
      this.setState((state) => ({
        ...state,
        expanded: !expanded,
      }));
    }
  };

  renderPrimaryColumn = (rowData, fromConsumerAccount) => {
    let { primaryColumn, rowLinkPath, fullRowIsClickable } = rowData;

    let rowDescriptor = primaryColumn.rowDescriptor ? (
      <div className='row-descriptor'>{primaryColumn.rowDescriptor}</div>
    ) : null;

    let primaryContent = (
      <div className='object-row-header-name'>{primaryColumn.title}</div>
    );

    let secondaryContent = primaryColumn.secondaryInfo ? (
      <div className='object-row-header-secondary-info'>
        {primaryColumn.secondaryInfo}
      </div>
    ) : null;

    if (rowLinkPath && !fullRowIsClickable) {
      return (
        <Link
          className={`object-row-header${
            fromConsumerAccount ? " primary-override-header" : ""
          }`}
          to={rowLinkPath}
        >
          {rowDescriptor}
          {primaryContent}
          {secondaryContent}
        </Link>
      );
    } else {
      return (
        <div
          className={`object-row-header${
            fromConsumerAccount ? " primary-override-header" : ""
          }`}
        >
          {rowDescriptor}
          {primaryContent}
          {secondaryContent}
        </div>
      );
    }
  };

  renderLatestPaymentDate = (payments) => {
    const latestPayment = payments.reduce(function (
      previousPayment,
      currentPayment
    ) {
      return previousPayment.timestamp > currentPayment.timestamp
        ? previousPayment
        : currentPayment;
    });

    let dateString = moment
      .unix(latestPayment.timestamp / 1000)
      .format("MM/DD/YYYY");

    return dateString;
  };

  renderOutstandingDebt = (debts) => {
    let totalDebt = debts.reduce((sum, debt) => {
      return sum + debt.balance;
    }, 0);

    return currencyFormatter.format(totalDebt, { code: "USD" });
  };

  renderSecondaryColumns = (columns, optionalClasses) => {
    let { fromOfferHistory, fromConsumerAccount } = this.props;
    let optClasses = fromOfferHistory
      ? "second-cell"
      : fromConsumerAccount
      ? "second-cell-rmv-padding"
      : "";
    if (columns.length > 0) {
      return (
        <div
          className={`object-row-data ${optionalClasses}${
            fromConsumerAccount ? " secondary-override-header-spacing" : ""
          }`}
        >
          {columns.map((column) => {
            return (
              <div
                key={shortid.generate()}
                className={`object-row-data-column ${"optClasses"}${
                  column.hideHeader ? " hide-header" : ""
                }${fromConsumerAccount ? " secondary-override-header" : ""} ${
                  column.isDownloadBtn ? " adjustDownloadHeader" : ""
                }${column.isAction ? " ation-btn-last-align image-last" : ""}`}
              >
                <div
                  className={`object-row-data-column-inner${
                    column.optionalClasses ? " " + column.optionalClasses : ""
                  }`}
                >
                  {column.title}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  renderRowActionButton = (rowType, rowActions) => {
    let { expanded } = this.state;
    if (rowType === "overflowMenu") {
      return (
        <DropdownOverflowMenu forTeam rowData={this.props.rowData}>
          {rowActions.map((rowAction) => {
            return (
              <MenuItem
                key={shortid.generate()}
                dataBehavior={rowAction.dataBehavior}
                dataTarget={rowAction.dataTarget}
                optionalClasses={rowAction.optionalClasses}
                handleClick={(event) => this.props.handleRowActionClick(event)}
              >
                {rowAction.title}
              </MenuItem>
            );
          })}
        </DropdownOverflowMenu>
      );
    } else if (rowType === "expander") {
      return (
        <a
          className={`object-row-expander${expanded ? " active" : ""}`}
          onClick={(event) => this.handleExpanderClick(event)}
        >
          <svg className='icon'>
            <use xlinkHref='#arrow-right' />
          </svg>
        </a>
      );
    } else if (rowType === "clickThrough") {
      return (
        <svg className='object-row-arrow'>
          <use xlinkHref='#arrow-right' />
        </svg>
      );
    }
  };

  renderExpanderContent = (expanderOptions) => {
    let { expanded } = this.state;

    if (expanderOptions) {
      return <ExpanderTable options={expanderOptions} isExpanded={expanded} />;
    }
  };

  getOptionalRowClasses = (rowData) => {
    let classes = "";

    if (rowData.rowType === "expander") {
      classes += " expander";
    }

    if (rowData.optionalRowClasses) {
      classes += " " + rowData.optionalRowClasses;
    }

    return classes;
  };

  render() {
    let { rowData, fromConsumerAccount } = this.props;
    let optionalClasses = this.getOptionalRowClasses(rowData);

    let rowInner = (
      <div className='object-row-inner'>
        <div className='object-row-content'>
          {this.renderPrimaryColumn(rowData, fromConsumerAccount)}
          {this.renderSecondaryColumns(
            rowData.secondaryColumns,
            optionalClasses
          )}
          {this.renderRowActionButton(rowData.rowType, rowData.rowActions)}
        </div>
        {this.renderExpanderContent(rowData.expanderOptions)}
      </div>
    );

    return rowData.fullRowIsClickable ? (
      <Link
        to={rowData.rowLinkPath}
        className={`object-row object-row-link${optionalClasses}`}
      >
        {rowInner}
      </Link>
    ) : (
      <div className={`object-row${optionalClasses}`}>{rowInner}</div>
    );
  }
}

export default ObjectRow;
