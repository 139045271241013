import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

class ConfirmAlertModal extends Component {
  handleClickOutside = () => {
    if (this.props.optionalProps.closeOnClickOutside) {
      this.props.hideModal();
    }
  };

  render() {
    let { message, title, methodData, isCancelCallback, btnName1, btnName2 } =
      this.props.optionalProps;
    return (
      <div className='confirm-modal'>
        <div className='modal-msg'>{message}</div>
        <div className='confirm-btns'>
          <button
            className='yes-btn'
            onClick={(event) => {
              this.props.optionalProps.confirmClick(methodData);
              this.props.hideModal();
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            {`${btnName1 ? btnName1 : "YES"}`}
          </button>
          {isCancelCallback ? (
            <button
              className='cancel-btn'
              onClick={() => {
                this.props.optionalProps.cancelClick(methodData);
                this.props.hideModal();
              }}
            >
              NO
            </button>
          ) : (
            btnName2 !== null && (
              <button
                className='cancel-btn'
                onClick={this.props.hideModal}
                style={{ whiteSpace: "nowrap" }}
              >
                {`${btnName2 ? btnName2 : "NO"}`}
              </button>
            )
          )}
        </div>
      </div>
    );
  }
}

export default onClickOutside(ConfirmAlertModal);
