import React, { useState, useEffect } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { withRouter } from "react-router-dom";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";
import moment from "moment";

const ClientReportFilterModal = ({
  user,
  reportSlug,
  selected_items,
  match,
  hideModal,
  closeModal,
}) => {
  const [selectedCheckBox, setSelectedCheckBox] = useState([]);
  const [agingSummaryData, setAgingSummaryData] = useState([]);
  const [selectedItems, setSelectedItems] = useState(selected_items || []);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    if (user) {
      // if (
      //   ["aging_detail", "invoices_and_received_payments"].includes(reportSlug)
      // ) {
      fetchAllClients();
      // } else {
      //   getReportDataForAgingSummary();
      // }
    }
  }, [user, reportSlug]);

  const handleSubmit = () => {
    hideModal(selectedCheckBox.length ? selectedCheckBox : selectedItems);
  };

  const getFormattedDate = (date) =>
    date ? moment(date).format("YYYY-MM-DD") : "";

  const getReportDataForAgingSummary = async () => {
    setIsLoading(true);
    try {
      const data = await API.arAgingSummaryList(
        user,
        match.params.id,
        "",
        "",
        []
      );
      if (data && data.length) {
        setAgingSummaryData(data.data);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllClients = async () => {
    setIsLoading(true);
    try {
      const data = await API.fetchAllClientsForTask();
      if (data && data.consumers && data.consumers.length) {
        setAgingSummaryData(data.consumers);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const setLocalId = () => {
    setAgingSummaryData((prevData) =>
      prevData.map((item, index) => ({
        ...item,
        localId: index,
        is_checked: selectedItems.includes(item.id || item.user_id),
      }))
    );
  };

  const onCheckboxClick = (event, item) => {
    const { name, checked } = event.target;
    const id = parseInt(name, 10);

    // Create a new updatedConsumers list
    const updatedConsumers = [...agingSummaryData].map((obj) =>
      obj.id === id || obj.user_id === id
        ? { ...obj, is_checked: checked }
        : obj
    );

    setSelectedCheckBox((prevSelectedCheckBox) =>
      checked
        ? [...prevSelectedCheckBox, id]
        : prevSelectedCheckBox.filter((selectedId) => selectedId !== id)
    );

    setAgingSummaryData(updatedConsumers);

    const allSelected = updatedConsumers.every(
      (consumer) => consumer.is_checked
    );
    setIsSelectAll(allSelected); // Update Select All state
  };

  const handleSelectAll = () => {
    const isChecked = !isSelectAll;
    setIsSelectAll(isChecked);

    setAgingSummaryData((prevData) =>
      prevData.map((consumer) => ({
        ...consumer,
        is_checked: isChecked,
      }))
    );

    setSelectedCheckBox(
      isChecked ? agingSummaryData.map((c) => c.id || c.user_id) : []
    );
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredData = agingSummaryData.filter(
    (item) =>
      item.display_name.toLowerCase().includes(searchText) ||
      (item.user_name && item.user_name.toLowerCase().includes(searchText))
  );

  const getObjectListData = () => ({
    headings: [],
    columns: filteredData.map((item) => ({
      primaryColumn: {},
      secondaryColumns: [
        {
          title: (
            <input
              name={item.id || item.user_id}
              value={item.id || item.user_id}
              key={item.localId}
              type='checkbox'
              checked={item.is_checked}
              style={{ WebkitAppearance: "checkbox", width: "20px" }}
              onClick={(event) => onCheckboxClick(event, item)}
              readOnly
            />
          ),
          opClassesPar: "task-checkbox",
        },
        {
          title: item.display_name || item.user_name,
        },
      ],
      fullRowIsClickable: false,
      optionalRowClasses: "object-row-inner-padding",
    })),
  });

  return (
    <Modal
      title='Select Clients for filter'
      closeOnClickOutside
      hideModal={handleSubmit}
      style={{ maxWidth: "35em", paddingBottom: "1em" }}
      handleModalClose={closeModal}
      optionalClasses='scroll task-popup-sp mxht'
      titleClasses='modal-header'
    >
      {isLoading ? (
        <AppCanvasLoader />
      ) : (
        <div>
          <div>
            <input
              type='text'
              placeholder='Search Clients...'
              value={searchText}
              onChange={handleSearchChange}
              style={{ padding: "10px", marginBottom: "1rem", width: "100%" }}
            />
            <div
              className='contact-count'
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <span>{`Selected Contacts: ${selectedCheckBox.length}`}</span>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <input
                  type='checkbox'
                  checked={isSelectAll}
                  style={{ WebkitAppearance: "checkbox", width: "20px" }}
                  onChange={handleSelectAll}
                />
                <span>Select All</span>
              </div>
            </div>
          </div>

          {filteredData.length > 0 && (
            <BottomScrollListener
              offset={50}
              onBottom={() => setOffset((prevOffset) => prevOffset + 1)}
            >
              {(scrollRef) => (
                <div
                  style={{
                    height: window.innerHeight / 2,
                    overflowY: "scroll",
                  }}
                >
                  <ObjectDebtList
                    data={getObjectListData()}
                    optionalClasses='mg-mb-0'
                    scrollRef={scrollRef}
                    removePadding
                    showLoader={false}
                    showScroll={true}
                  />
                </div>
              )}
            </BottomScrollListener>
          )}

          {hasError && (
            <div
              className='input-container error'
              style={{ marginBottom: "1.5em", marginTop: "-1em" }}
            >
              <div className='input-error-message' style={{ marginTop: 0 }}>
                Please Select Item
              </div>
            </div>
          )}

          <div
            className='submit-container'
            style={{ textAlign: "center", marginTop: "1em" }}
          >
            <a className='cta mg-brand2-color' onClick={handleSubmit}>
              Done
            </a>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withRouter(ClientReportFilterModal);
