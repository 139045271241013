import React, { useState, useEffect } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { withRouter } from "react-router-dom";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";

const ContactPreferences = ({
  user,
  selected_contacts = [],
  hideModal,
  item,
}) => {
  const [consumers, setConsumers] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([
    ...selected_contacts,
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (user) fetchSelectedContacts(false);
  }, [user]);

  const fetchSelectedContacts = async () => {
    setShowLoader(true);
    try {
      const data = await API.getSelectedContacts(user, item.id);

      if (data && !data.error) {
        const newConsumers = data.data.map((consumer) => ({
          ...consumer,
          is_selected: selectedContacts.includes(consumer.user_id), // Update only matching IDs
        }));

        setConsumers(newConsumers);

        setIsLoading(false);
        setShowLoader(false);
      } else {
        setHasError(true);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setHasError(true);
    }
  };

  const handleCheckboxToggle = (userId) => {
    setConsumers((prevConsumers) => {
      const updatedConsumers = prevConsumers.map((consumer) =>
        consumer.user_id === userId
          ? { ...consumer, is_selected: !consumer.is_selected }
          : consumer
      );

      // Check if all consumers are selected after toggle
      const allSelected = updatedConsumers.every(
        (consumer) => consumer.is_selected
      );

      setIsSelectAll(allSelected); // Update Select All state
      return updatedConsumers;
    });

    setSelectedContacts((prev) => {
      const updatedContacts = prev.includes(userId)
        ? prev.filter((id) => id !== userId) // Remove if already selected
        : [...prev, userId];

      setIsSelectAll(updatedContacts.length === consumers.length); // Update Select All state
      return updatedContacts;
    });
  };

  const handleSelectAll = () => {
    const isChecked = !isSelectAll;
    setIsSelectAll(isChecked);

    const updatedConsumers = consumers.map((consumer) => ({
      ...consumer,
      is_selected: isChecked,
    }));
    setConsumers(updatedConsumers);

    setSelectedContacts(
      isChecked ? updatedConsumers.map((c) => c.user_id) : []
    );
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    hideModal(selectedContacts, isSelectAll);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const handleContainerOnBottom = () => {
    setOffset((prevOffset) => prevOffset + 1);
    fetchSelectedContacts(true);
  };

  const filteredConsumers = consumers.filter((item) =>
    item.display_name.toLowerCase().includes(searchText)
  );

  const getObjectListData = () => ({
    headings: [],
    columns: filteredConsumers.map((item) => ({
      primaryColumn: {},
      secondaryColumns: [
        {
          title: (
            <input
              type='checkbox'
              name={item.user_id}
              value={item.user_id}
              key={item.user_id}
              checked={item.is_selected}
              style={{ WebkitAppearance: "checkbox", width: "20px" }}
              onChange={() => handleCheckboxToggle(item.user_id)}
            />
          ),
          opClassesPar: "task-checkbox",
        },
        {
          title: item.display_name || "",
        },
        {
          title: (
            <ToggleSwitch
              isOn={item.is_selected}
              toggleButton={() => handleCheckboxToggle(item.user_id)}
            />
          ),
          opClassesPar: "task task-toggle task-toggle-sm",
        },
      ],
      fullRowIsClickable: false,
      optionalRowClasses: "object-row-inner-padding",
    })),
  });

  return (
    <Modal
      title='Select Clients for Contact Preferences'
      closeOnClickOutside={true}
      hideModal={() => hideModal(selectedContacts)}
      style={{ maxWidth: `35em`, paddingBottom: "1em" }}
      optionalClasses='scroll task-popup-sp'
      titleClasses='modal-header'
    >
      {isLoading ? (
        <AppCanvasLoader />
      ) : (
        <div>
          <div>
            <input
              type='text'
              placeholder='Search Clients...'
              value={searchText}
              onChange={handleSearchChange}
              style={{ padding: "10px", marginBottom: "1rem", width: "100%" }}
            />
            <div
              className='contact-count'
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center" }}
              >{`Selected Contacts: ${selectedContacts.length}`}</span>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <input
                  type='checkbox'
                  checked={isSelectAll}
                  style={{ WebkitAppearance: "checkbox", width: "20px" }}
                  onChange={handleSelectAll}
                />
                <label>Select All</label>
              </div>
            </div>
          </div>

          {filteredConsumers.length > 0 && (
            <BottomScrollListener
              offset={50}
              onBottom={handleContainerOnBottom}
              debounce={0}
            >
              {(scrollRef) => (
                <div>
                  <ObjectDebtList
                    data={getObjectListData()}
                    scrollRef={scrollRef}
                    removePadding={true}
                    showLoader={showLoader}
                    showScroll={true}
                  />
                </div>
              )}
            </BottomScrollListener>
          )}

          {hasError && (
            <div
              className='input-container error'
              style={{ marginBottom: "1.5em", marginTop: "-1em" }}
            >
              <div className='input-error-message'>Please Select contact</div>
            </div>
          )}

          <div
            className='modal-actions'
            style={{ marginBottom: "2rem", textAlign: "center" }}
          >
            <a
              className='cta mg-brand2-color'
              style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
              onClick={handleSubmit}
            >
              Done
            </a>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withRouter(ContactPreferences);
