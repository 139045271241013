import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

// API
import * as API from "../../utils/api";
import { networkMessage, showToast } from "../../utils/helpers";

// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AdminAddAdminForm from "../../components/Admin/Forms/AdminAddAdminForm";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ObjectList from "../../components/ObjectList/ObjectList";
import ConfirmAdminDeletionModal from "../../components/App/Modals/Admin/ConfirmAdminDeletionModal";
import FlashMessage from "../../components/FlashMessage";
import ResetPasswordModal from "../../components/App/Modals/Client/ResetPasswordModal/index";

class AdminAdmins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAdminFormIsVisible: false,
      admins: [],
      defaultAdmins: [],
      isLoading: true,
      input: { search: "" },
      showAdminDeletionModal: false,
      showResetPasswordModal: false,
      adminToDelete: null,
      showFlash: false,
      flashMessage: "",
      adminID: "",
      error: { hasAnyError: false, statusCode: 200 },
    };
    this.updateAdminPassword = this.updateAdminPassword.bind(this);
  }

  componentDidMount() {
    this.fetchAdmins();
  }

  fetchAdmins = () => {
    const { user, organization_id } = this.props;
    API.fetchAllAdmins(user, organization_id).then((data) => {
      if (data && data.users) {
        this.setState({
          admins: data.users,
          defaultAdmins: data.users,
          isLoading: false,
        });
      } else {
        this.handleError(data);
      }
    });
  };

  handleError = (data) => {
    const statusCode =
      data && (data.status || (data.message === "Access denied." ? 401 : 500));
    this.setState({
      isLoading: false,
      error: { hasAnyError: true, statusCode },
    });
  };

  handleInputChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ input: { search: searchValue } });
  };

  handleSearch = () => {
    const { user } = this.props;
    const { search } = this.state.input;

    if (search) {
      API.adminSearch(user, "admins", search).then((data) => {
        this.setState({ admins: (data && data.results) || [] });
      });
    } else {
      this.setState((state) => ({ admins: state.defaultAdmins }));
    }
  };

  handleAddAdminClick = () => this.setState({ addAdminFormIsVisible: true });
  handleAddAdminFormCancelation = () =>
    this.setState({ addAdminFormIsVisible: false });

  handleRowActionClick = (e) => {
    const action = e.target.getAttribute("data-behavior");
    const adminId = e.target.getAttribute("data-target");

    switch (action) {
      case "admin-delete":
        this.setAdminToDelete(adminId);
        this.toggleAdminDeletionModal();
        break;
      case "admin-reset-password":
        this.toggleResetPasswordModal(adminId);
        break;
      case "admin-unlock-account":
        this.unlockAdminAccount(adminId);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  unlockAdminAccount = (id) => {
    API.unlockAccount(this.props.user, id).then((res) => {
      if (res && res.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: res.message,
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: res.message,
        });
      }
    });
  };

  setAdminToDelete = (adminId) => {
    const adminToDelete = this.state.admins.find(
      (admin) => admin.id === parseInt(adminId, 10)
    );
    this.setState({ adminToDelete });
  };

  toggleAdminDeletionModal = () => {
    this.setState((state) => ({
      showAdminDeletionModal: !state.showAdminDeletionModal,
    }));
  };

  toggleResetPasswordModal = (id) => {
    this.setState((state) => ({
      showResetPasswordModal: !state.showResetPasswordModal,
      adminID: id,
    }));
  };

  handleModalSubmission = (adminId) => {
    this.toggleAdminDeletionModal();
    this.deactivateAdmin(adminId);
  };

  deactivateAdmin = (adminId) => {
    const { user } = this.props;
    API.deactivateAdmin(user, adminId).then((data) => {
      if (data && !data.error) {
        this.fetchAdmins();
        this.setState({ showFlash: true, flashMessage: data.message });
      } else {
        this.handleError(data);
      }
    });
  };

  addAdminUser = (adminUser) => {
    API.createAdmin(this.props.user, adminUser).then((data) => {
      if (data && !data.errors) {
        this.fetchAdmins();
        this.setState({
          showFlash: true,
          flashMessage: `${adminUser.first_name} has been successfully added.`,
        });
      } else {
        this.setState({
          showFlash: true,
          error: { hasAnyError: true, statusCode: data.status || 500 },
          flashMessage: data.errors[0] || "Something went wrong.",
        });
      }
    });
  };

  getAdminsListData = () => {
    const { admins } = this.state;
    return {
      headings: ["Name", "Role", "Password", "Status"],
      columns: admins.map((admin) => ({
        primaryColumn: {
          title: `${admin.first_name} ${admin.last_name}`,
          secondaryInfo: admin.email,
        },
        secondaryColumns: [
          { title: admin.role },
          { title: admin.registration_token || "n/a" },
          {
            title: admin.active
              ? admin.deactivate_admin_id
                ? "Deletion Pending"
                : "Active"
              : "Inactive",
            optionalClasses: `status-pill ${
              admin.active ? "active" : "inactive"
            }`,
          },
        ],
        rowType: "overflowMenu",
        rowActions: [
          {
            title: admin.deactivate_admin_id
              ? "Confirm Delete"
              : "Delete Admin",
            dataBehavior: "admin-delete",
            dataTarget: admin.id,
          },
          {
            title: "Set Password",
            dataBehavior: "admin-reset-password",
            dataTarget: admin.id,
          },
          admin.is_locked && {
            title: "Unlock Account",
            dataBehavior: "admin-unlock-account",
            dataTarget: admin.id,
          },
        ].filter(Boolean),
      })),
    };
  };

  updateAdminPassword = (newCreds) => {
    API.updateAdminPassword(this.props.user, this.state.adminID, newCreds).then(
      (res) => {
        if (res && res.status_code == 200) {
          this.fetchAdmins();
          this.props.updateModalState(true, "SUCCESS", {
            message: res.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: res.message,
          });
        }
      }
    );
  };

  dismissFlash = () => this.setState({ showFlash: false });

  renderSectionHeaderUtilities = () => {
    return (
      <div className='app-section-header-utilities'>
        <form autoComplete='off'>
          <div className='admin-search-input-container'>
            <input
              type='text'
              name='search'
              placeholder='Search'
              value={this.state.input.search}
              className='admin-search-input'
              onChange={this.handleInputChange}
              autoComplete='admin-search-input'
            />
            <span className='search-icon' onClick={this.handleSearch} />
          </div>
        </form>
        {/* <button
          className={`cta mg-brand2-color ${
            this.state.addAdminFormIsVisible ? "hide" : ""
          }`}
          onClick={this.handleAddAdminClick}
        >
          + Add Admin
        </button> */}
      </div>
    );
  };

  render() {
    const {
      addAdminFormIsVisible,
      showAdminDeletionModal,
      showResetPasswordModal,
      isLoading,
      showFlash,
      flashMessage,
      admins,
      adminToDelete,
      error,
    } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode === 401) return <Redirect to='/sign-out' />;
      showToast();
      this.setState({ error: { hasAnyError: false, statusCode: 200 } });
    }

    if (isLoading) return <AppCanvasLoader />;

    return (
      <div className='admin-admins'>
        {showFlash && (
          <FlashMessage
            message={flashMessage}
            handleFlashDismiss={this.dismissFlash}
          />
        )}
        <AppSectionHeader
          title='Admins'
          utilities={this.renderSectionHeaderUtilities()}
        />

        {addAdminFormIsVisible && (
          <AdminAddAdminForm
            closeForm={this.handleAddAdminFormCancelation}
            handleFormSubmission={this.addAdminUser}
          />
        )}

        {admins.length > 0 ? (
          <ObjectList
            optionalClasses='admins'
            data={this.getAdminsListData()}
            rowActionClick={this.handleRowActionClick}
          />
        ) : (
          <div className='admin-admins-empty-state'>
            <p>No results found for:</p>
            <h3>{this.state.input.search}</h3>
          </div>
        )}

        {showAdminDeletionModal && (
          <>
            <ConfirmAdminDeletionModal
              user={this.props.user}
              admin={adminToDelete}
              submitAndRemoveAdmin={this.handleModalSubmission}
              hideModal={this.toggleAdminDeletionModal}
            />
            <div className='curtain'></div>
          </>
        )}
        {showResetPasswordModal && (
          <ResetPasswordModal
            isOpen={this.state.showResetPasswordModal}
            toggle={this.toggleResetPasswordModal}
            onReset={this.updateAdminPassword}
          />
        )}
      </div>
    );
  }
}

export default AdminAdmins;
