import moment from "moment";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import { getZohoPagesense, formatDate } from "../../utils/helpers";
import BottomScrollListener from "react-bottom-scroll-listener";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import * as API from "../../utils/api";

class NotificationHistory extends Component {
  state = {
    savedData: [],
    isLoading: true,
    offset: 0,
    hasMoreData: true,
    search: { value: "" },
  };

  componentDidMount() {
    getZohoPagesense();
    this.getLobData();
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      this.setState(
        (prevState) => ({ offset: prevState.offset + 1 }),
        this.getLobData
      );
    }
  };

  getLobData = async () => {
    const { user } = this.props;
    const { offset, savedData } = this.state;

    // this.setState({ isLoading: true });

    try {
      const data = await API.fetchNotificationHistory(user, offset, null);

      if (data && data.message === "Access denied.") {
        this.setState({
          isLoading: false,
          error: { hasAnyError: true, statusCode: 401 },
        });
        return;
      }

      if (data && data.status_code === 200 && data.notifications) {
        this.setState({
          savedData: savedData.concat(data.notifications),
          hasMoreData: data.notifications.length >= 20,
          isLoading: false,
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data && data.message ? data.message : "An error occurred",
          title: "Info!",
        });
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: { hasAnyError: true, statusCode: 500 },
      });
    }
  };

  getObjectListData = (data) => ({
    headings: [
      "Customer Name",
      "QuickBooks Error detail",
      "Entity No.",
      "Entity Type",
      "Error Msg.",
      "Created At",
      "Updated At",
      "Addressed By",
    ],
    columns: data.map((row) => ({
      emptyPrimary: true,
      secondaryColumns: [
        { key: "Customer Name", title: row.account_name },
        { key: "QuickBooks Error detail", title: row.qb_error_description },
        { key: "Entity No.", title: row.mg_entity_number },
        { key: "Entity Type", title: row.entity_type },
        { key: "Error Msg.", title: row.error_message },
        {
          key: "Created At",
          title: row.created_at ? (
            <div style={{ textAlign: "center" }}>
              {formatDate(row.created_at).join(" ")}
            </div>
          ) : (
            "n/a"
          ),
        },
        {
          key: "Updated At",
          title: row.updated_at ? (
            <div style={{ textAlign: "center" }}>
              {formatDate(row.updated_at).join(" ")}
            </div>
          ) : (
            "n/a"
          ),
        },
        {
          key: "Addressed By",
          title:
            row.is_addressed && row.addressed_by
              ? row.addressed_by.first_name + " " + row.addressed_by.last_name
              : "Issue is not addressed by admin",
        },
      ],
    })),
  });

  handleSearchInputChange = (newPartialInput) => {
    this.setState({ search: { value: newPartialInput.search.value } });

    if (!newPartialInput.search.value.length) {
      this.handleKeyPress({ key: "Enter" });
    }
  };

  render() {
    const { isLoading, savedData } = this.state;
    const isSmallDevice = window.innerWidth <= 680;

    if (isLoading) return <AppCanvasLoader />;

    return (
      <>
        <div style={{ marginBottom: "1em" }} />
        {savedData.length > 0 ? (
          <BottomScrollListener
            offset={50}
            onBottom={this.handleContainerOnBottom}
          >
            {(scrollRef) => (
              <div>
                {isSmallDevice ? (
                  <div className='big-device-grad invoice-line'>
                    <div ref={scrollRef} className='scrollbar in-sc'>
                      <ClientsCardView
                        data={this.getObjectListData(savedData)}
                        rowActionClick={this.handleRowActionClick}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: "1em" }}>
                    <HistoryObjectList
                      data={this.getObjectListData(savedData)}
                      rowActionClick={this.handleRowActionClick}
                      hideForMobile={true}
                      optionalClasses='scrollbar in-sc'
                      scrollRef={scrollRef}
                    />
                  </div>
                )}
                {this.state.showLoader && <AppCanvasLoader />}
              </div>
            )}
          </BottomScrollListener>
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='empty-mesg-mg'>
              No notification history available.
            </h3>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(NotificationHistory);
