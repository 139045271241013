import React, { Component } from "react";
import Modal from "./Modal";
import StripeLogo from "../../../assets/icons/stripe_logo.svg";
import * as API from "../../../utils/api";

class DeclineEULAModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      showLoader: false,
    };
  }

  componentDidMount() {}

  reverseCreditMemo = (item) => {};

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    return (
      <Modal
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses='scroll'
      >
        <div
          style={{
            marginTop: "1.5em",
          }}
        >
          <div
            style={{
              color: "#000000",
              fontFamily: "Open sans",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            <p
              style={{
                margin: "0px",
                color: "#000000",
                fontFamily: "Open sans",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Your account cannot be created without acceptance of terms and
              conditions.
            </p>
          </div>
          <div
            style={{
              marginTop: "20px",
              justifyItems: "center",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <input
              type='submit'
              value={"Okay"}
              className='cta mg-brand2-color'
              style={{
                display: "flex",
                justifyContent: "center",
                width: "fit-content",
                borderRadius: "0.5em",
                border: "2px solid",
                padding: "7px 10px",
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: "600",
              }}
              onClick={this.handleModalClose}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default DeclineEULAModal;
